import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import Link from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import ModalConfirm from '@uz/unitz-components-web/ModalConfirm';
import CourseListUseTemplate from '@uz/unitz-tool-components/CourseListUseTemplate';

const View8 = () => {
  return (
    <DIV className="component">
      <div className="px-8 py-6 w-full bg-white500 rounded-lg">
        <Row gutter={[24, 24]} align="middle" justify="space-between">
          <Col>
            <Link
              to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplateDetail', ctx.get('courseTemplateItemInfo'))}
            >
              <div className="underline uppercase text-base font-semibold">
                {ctx.get('courseTemplateItemInfo.name')}
              </div>
            </Link>
            <CourseListUseTemplate data={ctx.get('courseTemplateItemInfo')} />
          </Col>
          <Col>
            <Row gutter={[16, 16]}>
              <Col className="w-full sm:w-auto">
                <Link
                  to={ctx.apply('routeStore.toUrl', 'toolAccountCourseUseTemplate', ctx.get('courseTemplateItemInfo'))}
                >
                  <Button block size="medium" type="primary">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.start_course')}
                  </Button>
                </Link>
              </Col>
              <Col className="w-full sm:w-auto">
                <Link
                  to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplateEdit', ctx.get('courseTemplateItemInfo'))}
                >
                  <Button
                    block
                    style={{ border: `1px solid ${gstyles.colors.placeHolder}` }}
                    size="medium"
                    type="outline"
                  >
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.edit')}
                  </Button>
                </Link>
              </Col>
              <Col className="w-full sm:w-auto">
                <Button
                  style={{ border: `1px solid ${gstyles.colors.placeHolder}` }}
                  size="medium"
                  type="outline"
                  block
                  onClick={ctx.get('courseTemplateItemInfo.dulicateCourseTemplate')}
                >
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.duplicate')}
                </Button>
              </Col>
              <Col className="w-full sm:w-auto">
                <ModalProvider>
                  <Button
                    size="medium"
                    name="red"
                    type="primary"
                    block
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return (
                          <ModalConfirm
                            icon={gstyles.icons({ name: 'warning', size: 52 })}
                            title={ctx.apply('i18n.t', 'ZoomToolCourseTemplate.confirm_delete_title')}
                            description={
                              <div className="px-4 text-xl font-semibold text-main">
                                {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.confirm_delete_description')}
                              </div>
                            }
                            confirm={() => {
                              return (
                                <Button
                                  type="primary"
                                  className="w-full"
                                  onClick={() => {
                                    ctx.apply('modalModel.hide');
                                  }}
                                >
                                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.cancel')}
                                </Button>
                              );
                            }}
                            ignore={() => {
                              return (
                                <Button
                                  name="red"
                                  type="primary"
                                  className="w-full"
                                  onClick={ctx.get('courseTemplateItemInfo.deleteCourseTemplate')}
                                >
                                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.delete')}
                                </Button>
                              );
                            }}
                          />
                        );
                      });
                    }}
                  >
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.delete')}
                  </Button>
                </ModalProvider>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </DIV>
  );
};

export default displayName(View8);
