import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import Link from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import _ from 'lodash';
import moment from 'moment';
import i18n from 'i18n-js';
import { START_DATE_YEAR_ML_FORMAT } from '@vl/mod-utils/datetime';

const CustomList = styled.div`
  justify-content: flex-start;
  .course-item {
    border-right: 2px solid ${gstyles.colors.sub};
    padding: 0 12px;
  }
  .course-item:first-child {
    padding-left: 0;
  }
  .course-item:last-child {
    border-right: none;
  }
`;

export const CourseListUseTemplate = ({ data }) => {
  return (
    <DIV className="component" forceCtx>
      <div className="flex flex-row items-center flex-wrap">
        {renderPropsComposer(
          {
            matcher: (props) => !_.get(props, 'courses.length'),
            render: () => (
              <div className="mt-3 mb-2 text-sub font-semibold text-xs sm:text-sm">
                {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Status.emptyCourse')}
              </div>
            ),
          },

          {
            matcher: (props) => !!_.get(props, 'courses.length'),
            render: (props) => (
              <div>
                <div className="my-2 text-sub font-semibold text-sm">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Status.hasCourse')}
                </div>
                <CustomList className="flex flex-wrap items-start">
                  {_.map(_.get(props, 'courses', []), (item, index) => {
                    const date = moment(_.get(item, 'start_at', ''))
                      .locale(i18n.locale)
                      .format(_.get(START_DATE_YEAR_ML_FORMAT, moment.locale(i18n.locale), 'de'));
                    return (
                      <Link
                        target="_blank"
                        to={ctx.apply('routeStore.toUrl', 'toolAccountCourseDetail', item)}
                        className="course-item cursor-pointer underline font-normal text-xs sm:text-sm text-brandb500"
                        key={index}
                      >
                        {date}
                      </Link>
                    );
                  })}
                </CustomList>
              </div>
            ),
          },
          () => null
        )(data)}
      </div>
    </DIV>
  );
};

export default CourseListUseTemplate;
