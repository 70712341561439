import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import _ from 'lodash';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import gstyles from '@vl/gstyles';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { Row, Col } from 'antd';
import { Button } from '@uz/unitz-components-web/Button';
import { Link } from '@uz/unitz-components-web/Link';
import { Form } from 'formik-antd';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import CourseTemplateItemX from '@uz/unitz-tool-components/CourseTemplateItemX';
import Paginator from '@uz/unitz-components-web/Paginator';
import ToolSearchInput from '@uz/unitz-tool-components/ToolSearchInput';
import GuideBubble from '@uz/unitz-tool-components/GuideBubble';
import { QoS } from '@vl/mod-utils/QoS';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const CourseHeader = () => {
  return (
    <DIV>
      {!ctx.get('paginationModel.isLoading') && (
        <ResponsiveProps
          xs={{
            renderer: () => (
              <div className="py-4">
                <div className="text-brandb500 font-semibold text-2xl mb-6">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.title')}
                </div>
                <Row justify="end">
                  <Col>
                    <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplateCreate')}>
                      <Button
                        type="primary"
                        size="medium"
                        disabled={!QoS.can('course.insert')}
                        tooltip={
                          !QoS.can('course.insert') &&
                          ctx.apply('i18n.rt', `Subscription limit (${QoS.getRule('course.insert')?.current_count}/${QoS.getRule('course.insert')?.count})`)
                        }
                      >
                        <div className="flex items-center">
                          <div className="text-white500 font-semibold">
                            {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_course')}
                          </div>
                          <div>
                            {gstyles.icons({
                              name: 'plus',
                              size: 14,
                              fill: gstyles.colors.white500,
                              className: 'mx-2',
                            })}
                          </div>
                        </div>
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            ),
          }}
          md={{
            renderer: () => (
              <div className="flex w-full justify-between items-center py-4">
                <div className="text-main uppercase font-semibold text-base">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.title')}
                </div>
                <Row justify="end">
                  <Col>
                    <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplateCreate')}>
                      <Button
                        type="primary"
                        size="medium"
                        disabled={!QoS.can('course.insert')}
                        tooltip={
                          !QoS.can('course.insert') &&
                          ctx.apply('i18n.rt', `Subscription limit (${QoS.getRule('course.insert')?.current_count}/${QoS.getRule('course.insert')?.count})`)
                        }
                      >
                        <div className="flex items-center">
                          <div className="text-white500 font-semibold">
                            {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_course')}
                          </div>
                          <div>
                            {gstyles.icons({
                              name: 'plus',
                              size: 14,
                              fill: gstyles.colors.white500,
                              className: 'mx-2',
                            })}
                          </div>
                        </div>
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            ),
          }}
        >
          {(resProps) => resProps && resProps.renderer()}
        </ResponsiveProps>
      )}
    </DIV>
  );
};

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4 h-full">
          <div className="template-list">
            <FormProvider form={ctx.get('filterForm')}>
              <Form {...layout} className="CourseTemplateFilterMenu">
                <div className="bg-white500 rounded-lg p-6">
                  <Row>
                    <Col xs={24} lg={6}>
                      <ToolSearchInput
                        name="search"
                        onKeyDown={ctx.get('filterForm.handleKeyDown')}
                        label={ctx.apply('i18n.t', 'ZoomToolMember.Form.search_label')}
                        onClick={ctx.get('filterForm.handleSubmit')}
                      />
                    </Col>
                  </Row>
                </div>
                <CourseHeader />
                {renderPropsComposer(
                  {
                    matcher: (props) =>
                      !_.get(props, 'items.length') &&
                      !_.get(props, 'isLoading') &&
                      !ctx.apply('paginationModel.hasFilterArgs'),
                    render: () => (
                      <>
                        <Row justify="center" className="py-16">
                          <Col span={12}>
                            <div className="flex flex-col items-center justify-center">
                              <div className="mb-4">
                                {gstyles.icons({
                                  name: 'courseEmpty',
                                  size: 152,
                                })}
                              </div>
                              <div className="mb-6 text-center text-2xl font-semibold text-sub">
                                {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.empty_text1')}
                              </div>
                              <div className="text-2xl text-center font-semibold text-sub">
                                {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.empty_text2')}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <GuideBubble />
                      </>
                    ),
                  },
                  {
                    matcher: (props) => !_.get(props, 'items.length') && !!_.get(props, 'isLoading'),
                    render: () => (
                      <div className="fixed flex justify-center items-center w-full h-fill-screen top-0 left-0 select-none bg-white200">
                        <LoadingScreen />
                      </div>
                    ),
                  },
                  {
                    matcher: (props) => !!_.get(props, 'items.length') || ctx.apply('paginationModel.hasFilterArgs'),
                    render: () => (
                      <>
                        <Row className="px-4" gutter={[24, 16]} justify="center">
                          {_.map(ctx.get('paginationModel.items'), (item, index) => (
                            <React.Fragment key={index}>
                              <DIV forceCtx>
                                {ctx.debug(() => {
                                  ctx.set('@item', item);
                                })}
                                <CourseTemplateItemX />
                              </DIV>
                            </React.Fragment>
                          ))}
                        </Row>
                        <Paginator />
                      </>
                    ),
                  },
                  () => null
                )(ctx.get('paginationModel'))}
              </Form>
            </FormProvider>
          </div>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
